<template>
    <div>
        <el-dialog
            title="拒绝"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="refuseType" label="拒绝类型">
                        <el-select v-model="form.refuseType" >
                            <el-option v-for="item in refuseTypeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="refuseReason" label="拒绝原因">
                        <el-input
                            type="textarea"
                            :rows="4"
                            maxlength="200"
                            placeholder="请输入拒绝原因"
                            v-model="form.refuseReason">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                id: '',
                form: {
                    refuseType: '',
                    refuseReason: '',
                    reviewStatus: 2,//1通过,2拒绝
                },
                rules: {
                    refuseType: [{required: true, message:'请选择拒绝类型', trigger: 'change'}],
                    refuseReason: [{required: true, message:'请输入拒绝原因', trigger: 'blur'}],
                },
                refuseTypeDic: [{
                    label: '重复提交',
                    value: 1
                },{
                    label: '信息核验失败',
                    value: 2
                }]
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        created () {
            // this.$getDic('bank_account_type','select').then(res=>{ this.bank_account_typeDic = res; })
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let reqData = {
                            ...this.form,
                            id: this.id
                        }
                        this.$confirm('是否确认提交？','温馨提示')
                        .then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.taskReview,
                                method: "post",
                                params: reqData
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.dialogVisible = false
                                    this.$emit('submit')
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        })
                        .catch(_=>{})
                    }else{
                        this.$message.error('请检查所填参数是否正确')
                    }
                })
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>