<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main">
            <div slot="header" class="clearfix">
                <span class="a-fw-700">{{ form.taskName || '-' }}</span>
                <el-tag effect="dark" :type="reviewStatusColorDic[form.reviewStatus]" class="a-ml-16">{{ form.reviewStatus| initDic(reviewStatusDic) }}</el-tag>
            </div>
            <div class="a-flex-rfsfs a-flex-wrap">
                <el-form :model="form" ref="form" label-width="145px" label-position="left" v-if="form" class="a-flex-1">
                    <el-form-item label="小区名称" prop="name" >
                        <span>{{ form.communityName || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="小区位置" prop="account_info">
                        <span>{{ form.address || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="小区户数" prop="billType" >
                        <span>{{ form.householdNum || 0 }}</span>
                    </el-form-item>
                    <!-- <el-form-item label="电瓶车数量" prop="billType" >
                        <span>{{ form.bikeNum || 0 }}</span>
                    </el-form-item> -->
                    <el-form-item label="充电桩插槽数量" prop="billType" >
                        <span>{{ form.slotNum || 0 }}</span>
                    </el-form-item>
                    <el-form-item label="饮水机售后电话" prop="billType" >
                        <span>{{ form.waterPhone || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="垃圾回收售后电话" prop="billType" >
                        <span>{{ form.garbagePhone || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="广告闸机售后电话" prop="billType" >
                        <span>{{ form.adGatePhone || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="是否有电梯" prop="alertCycle" >
                        <span>{{ form.lift == 1 ? '有电梯' : '没有电梯' }}</span>
                    </el-form-item>
                    <el-form-item label="是否有充电桩" prop="alertDate" >
                        <span>{{ form.charge == 1 ? '有充电桩' : '没有充电桩' }}</span>
                    </el-form-item>
                    <el-form-item label="充电桩品牌" prop="dueDate" v-if="form.charge">
                        <span>{{ form.chargeBrand || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="充电桩售后电话" prop="dueDate" v-if="form.charge">
                        <span>{{ form.phone || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="充电桩照片" prop="dueDate" v-if="form.charge">
                        <div class="a-flex-rfsc">
                            <el-image
                                v-for="(item, index) in form.chargePic"
                                :key="index"
                                style="width: 100px; height: 100px; margin-right: 10px;border-radius: 6px"
                                :preview-src-list='form.chargePic'
                                :src="item">
                            </el-image>
                        </div>
                    </el-form-item>
                    <el-form-item label="充电桩场景照片" prop="dueDate" v-if="form.charge">
                        <div class="a-flex-rfsc">
                            <el-image
                                v-for="(item, index) in form.chargePlacePic"
                                :key="index"
                                style="width: 100px; height: 100px; margin-right: 10px;border-radius: 6px"
                                :preview-src-list='form.chargePlacePic'
                                :src="item">
                            </el-image>
                        </div>
                    </el-form-item>
                    <el-form-item label="审核未通过类型" prop="dueDate" v-if="form.reviewStatus == 2">
                        <span class="a-c-error">{{ form.refuseType | initDic(refuseTypeDic) }}</span>
                    </el-form-item>
                    <el-form-item label="审核未通过原因" prop="dueDate" v-if="form.reviewStatus == 2">
                        <span class="a-c-error">{{ form.refuseReason || '-' }}</span>
                    </el-form-item>
                    <div style="height: 50px;"></div>
                </el-form>
                <village-info-nearby class="a-flex-1" :locationInfo='form'></village-info-nearby>
            </div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit('pass')" v-if="form.reviewStatus == 0">通过</el-button>
                <el-button type="danger" class="a-ml-24 a-mt-15" @click="submit('refuse')" v-if="form.reviewStatus == 0">拒绝</el-button>
                <el-button class="a-ml-24 a-mt-15" @click="cancelSubmit">返回</el-button>
            </div>
        </el-card>
        <task-audit ref="taskAudit" @submit="auditRefuse"></task-audit>
    </div>
</template>

<script>
import taskAudit from './child/task-audit.vue'
import VillageInfoNearby from './child/village-info-nearby.vue'
export default {
  components: { taskAudit, VillageInfoNearby },
    data () {
        return {
            id: '',
            form: {
     
            },
            reviewStatusColorDic: {
                0: '',
                1: 'success',
                2: 'danger'
            },
            reviewStatusDic: [],
            refuseTypeDic: [{
                label: '重复提交',
                value: 1
            },{
                label: '信息核验失败',
                value: 2
            }]
        }
    },
    mounted () {
        this.$getDic('reviewStatus','select').then(res=>{ this.reviewStatusDic = res; })
        this.id = this.$route.query.id
        if(this.id) {
            this.getDetail()
        }
    },
    methods:{
        // 查询详情
        getDetail () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getCommunityQuestionDetail,
                method: "post",
                params: {
                    id: this.id
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.form = Object.assign({},res.result.data)
                    try {
                        this.form.chargePic = JSON.parse(this.form.chargePic)
                        this.form.chargePlacePic = JSON.parse(this.form.chargePlacePic)
                    } catch (error) {
                        
                    }
                    console.log(this.form);
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
  
        submit (type) {
            if(type == 'pass') {
                this.auditPass()
            }else if(type == 'refuse') {
                this.$refs['taskAudit'].id = this.id
                this.$refs['taskAudit'].dialogVisible = true
            }
        },
        auditPass () {
            this.$confirm('是否确认审核通过？','温馨提示')
            .then(_=>{
                let reqData = {
                    id: this.id,
                    reviewStatus: 1,//1通过,2拒绝
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.taskReview,
                    method: "post",
                    params: reqData
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.getDetail()
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            })
            .catch(_=>{})
        },
        auditRefuse () {
            this.getDetail()
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        }
    },
}
</script>

<style lang="scss" scoped>
.send-user{
    background: #53A8FF;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 10px 10px 0;
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 12px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 150px;
    margin-right: 16px;
}
.content{
    width: 240px
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
</style>