<template>
    <div>
        <div class="pageTitle a-flex-rfsc">附近已登记小区信息</div>
        <le-pagview v-if="pageParam" ref="taskListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="false" :tableRef="$refs.taskList" :pageSizeDefault='10'>
            <el-table :border='true' ref="taskList" :data="tableData" :highlight-current-row="true" style="width: 100%">
                <el-table-column prop="id" label="小区名称" min-width="140">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsfs">
                            <el-tag effect="dark" size='mini' v-if="row.communityName == locationInfo.communityName">当前小区</el-tag>
                            <span style="padding-left:10px">{{ row.communityName || '-' }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="taskName" label="小区位置" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.userAddress || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="cityName" label="距离" min-width="100">
                    <template slot-scope="{ row }">
                        <span>{{ row.distance || 0 }}米</span>
                    </template>
                </el-table-column>
                <el-table-column prop="districtName" label="充电桩品牌" min-width="100">
                    <template slot-scope="{ row }">
                        <span>{{ row.chargeBrand || '-' }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageParam: null,
                tableData: [],
            };
        },
        props: {
            locationInfo: {
                default: ''
            }
        },
        watch: {
            locationInfo: {
                deep: true,
                handler (val) {
                    this.pageParam = {
                        url: this.$Config.apiUrl.communityQuestionGeo,
                        method: "post",
                        params: {
                            ...val
                        },
                        freshCtrl: 1,
                    }
                }
            }
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
        }
    }
</script>

<style lang="scss" scoped>
    .pageTitle{
        line-height: 32px;
        color: #333333;
        font-weight: 700;
        margin-bottom: 12px;
    }
    .pageTitle:before {
        display: block;
        content: "";
        width: 4px;
        height: 13px;
        background: #007af0;
        border-radius: 4px;
        margin-right: 6px;
    }
    .content{
        width: 100%;
        height: 150px;
        overflow: auto;
        font-size: 14px;
        align-items: stretch;
        .list-item{
            padding: 12px;
            border-radius: 8px;
            background: #F7F7F7;
            margin-top: 12px;
        }
    }
</style>